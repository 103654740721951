<template>
  <a-row :gutter="20">
    <a-col :span="12">
      <a-card>
        <div class="header_title">
          <h3>随手拍封面挑战热度变化</h3>
          <p>收录时间：{{ includeTime }}</p>
        </div>

        <div>
          <SimpleLineChart
            :id="'linechant'"
            :box-style="{ width: '100', height: '300' }"
            :chart-data="tendecyList"
          />
        </div>
      </a-card>
    </a-col>
    <a-col :span="12">
      <a-card>
        <h3>随手拍封面挑战热度变化列表</h3>
        <div class="table_box">
          <!-- 表格 -->
          <a-table
            :columns="columns"
            :data-source="list_data"
            :pagination="false"
            :rowKey="(record, index) => index"
            :loading="loading"
            :scroll="{ y: 240 }"
          >
            <span slot="hot_value" slot-scope="text">
              {{ text | changeNum }}
            </span>
            <span slot="operation_value" slot-scope="text">
              {{ text | changeNum }}
            </span>
          </a-table>
        </div>
      </a-card>
    </a-col>
    <a-col :span="24" style="margin-top: 20px">
      <a-card>
        <h3>随手拍封面挑战相关视频</h3>
        <div class="video-box" v-if="videoList.length">
          <VideoItem
            v-for="(item, index) in videoList"
            :key="index"
            :videoData="item"
          ></VideoItem>
        </div>
        <div
          v-else
          style="
            margin: 50px auto;
            color: #ccc;
            font-size: 18px;
            font-weight: 600;
            text-align: center;
          "
        >
          暂无数据
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>
<script>
import SimpleLineChart from "@/components/SimpleLine";
import VideoItem from "@/components/VideoItem";
const columns = [
  {
    title: "时间",
    dataIndex: "job_time",
    // scopedSlots: { customRender: "awemeId" },
  },
  {
    title: "热度值",
    dataIndex: "hot_value",
    scopedSlots: { customRender: "hot_value" },
  },
  {
    title: "热度值变化",
    dataIndex: "operation_value",
    scopedSlots: { customRender: "operation_value" },
  },
];
export default {
  components: {
    SimpleLineChart,
    VideoItem,
  },
  data() {
    return {
      aweme_id: this.$route.query.id,
      tendecyList: {
        xAxisData: [],
        yAxisData: [],
      },
      columns,
      loading: false,
      list_data: [],
      videoList: [],
      sentence_id: this.$route.query.sentence,
      includeTime: "",
    };
  },
  created() {
    this.getChallengeDetail();
    this.getChallengeTendency();
  },
  methods: {
    // 分页事件-修改当前页码、修改当前页数
    onChange(current, pageSize) {
      this.getDataList(current, pageSize);
    },
    getChallengeDetail() {
      this.$api.core.Polymers.challengeDetail(this.sentence_id).then((res) => {
        console.log("视频卡片返回", res);
        if (res.data.length) {
          this.videoList = res.data;
        }
      });
    },
    // 随手拍封面挑战热度变化列表
    getChallengeTendency() {
      this.$api.core.Polymers.challengeTendency(this.sentence_id).then(
        (res) => {
          if (res.data.length) {
            let i = res.data.length - 1;
            this.includeTime = res.data[i].job_time;
            this.list_data = res.data;
            this.tendecyList = this.disposeChart(res.data);
          }
        }
      );
    },
    // 处理E chart数据
    disposeChart(data) {
      console.log("需要处理的data", data);
      let chartData = {
        xAxisData: [],
        yAxisData: [],
      };
      chartData.yAxisData = data.map(({ hot_value }) => hot_value);
      chartData.xAxisData = data.map(({ job_time }) => job_time);
      chartData.xAxisData = chartData.xAxisData.map((item) => {
        return item.slice(5, -3);
      });
      return chartData;
    },
  },
};
</script>
<style lang="less" scoped>
h3 {
  color: #4a58f3;
  border-left: 4px solid #4a58f3;
  font-size: 16px;
  font-weight: 600;
  padding-left: 10px;
}
.header_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.table_box {
  height: 303px;
}
.video-box {
  display: flex;
  // justify-content: flex-end;
  width: 100%;
  overflow-x: scroll;
}
</style>