<template>
  <div class="video-item" :style="parentStyle" v-if="videoData">
    <div class="video-item-top">
      <img :src="videoData.aweme_cover" alt="" :width="imgWidth" />
      <div class="mask" :style="mskPadding">
        <div class="title">
          {{ videoData.aweme_title }}
        </div>
        <div class="time">
          <a-icon type="clock-circle" style="margin-right:4px"/>
          <span>{{ videoData.publish_time }}</span>
        </div>
        <div class="action">
          <div>
            <a-icon type="heart" style="color: red" />
            <span>{{ this.filtrate.changeNum(videoData.digg_count, 1) }}</span>
          </div>
          <div>
            <a-icon type="message" theme="filled" style="color: skyblue" />
            <span>{{
              this.filtrate.changeNum(videoData.comment_count, 1)
            }}</span>
          </div>
          <div>
            <a-icon type="branches" style="color: orange" />
            <span>{{ this.filtrate.changeNum(videoData.share_count, 1) }}</span>
          </div>
        </div>
      </div>
      <div class="mask-button">
        <a-button
          style="
            background-image: linear-gradient(-84deg, #fbbe6c 0%, #fc9365 100%);
            border: none;
          "
          type="primary"
          shape="round"
          @click="openVideo(videoData.share_url)"
          ><a-icon type="play-circle" />播放视频</a-button
        >
        <!-- <a-button
          style="
            background: linear-gradient(209deg, #64A9F3 0%, #516EF3 100%);
            margin: 0;
            border: none;
          "
          type="primary"
          shape="round"
          size="large"
          ><a-icon type="stock" />视频详情</a-button> -->
      </div>
    </div>
    <!-- <div class="video-item-bottom">
      <img :src="videoData.avatar || (videoData.author && videoData.author.avatar) || defultImg" alt="头像">
      <div>
        <a-tooltip :content="videoData.nickname || (videoData.author && videoData.author.nickname) || '---'" placement="top" effect="light">
          <span class="name">{{videoData.nickname || (videoData.author && videoData.author.nickname) || '---'}}</span>
        </a-tooltip>
        <a-tooltip :content="'抖音号' + videoData.code || '抖音号 ---'" placement="top" effect="light">
          <span class="number">抖音号 {{videoData.code || '---'}}</span>
        </a-tooltip>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  // props: ["videoData"],
  props: {
    videoData: {
      type: Object,
    },
    parentStyle: {
      type: Object,
      default: () => {
        return { width: "170px", height: "280px", margin: "0 16px 12px 0" };
      },
    },
    imgWidth: {
      type: String,
      default: "168px",
    },
    mskPadding: {
      style: Object,
      default: () => {
        return { padding: "15px 8px 5px" };
      },
    },
    isShowDetailButton: {
      style: Boolean,
      default: true,
    },
  },
  data() {
    return {
      defultImg:
        "https://afanticar-test.oss-cn-hangzhou.aliyuncs.com/aftpm/images/defultImg.jpg",
      // videoData: {
      //   comment_count: "3",
      //   share_count: "1",
      //   aweme_title: "SUV销量冠军哈弗H6的合金模型 1:18红标版",
      //   aweme_id: "6736329142041677060",
      //   code: "632099856",
      //   aweme_topic: [],
      //   digg_count: "16",
      //   publish_time: "2019-09-14 09:17:34",
      //   nickname: "汽车模型.普鲸\uD83D\uDE04",
      //   aweme_cover:
      //     "http://p9-dy.byteimg.com/large/tos-cn-p-0015/aa5835e2e9f74bf68c98c032a51beddf.jpeg?from=2563711402_large",
      //   avatar:
      //     "https://p1-dy-ipv6.byteimg.com/aweme/720x720/2d5890002f0541744dd5b.jpeg",
      // },
    };
  },
  computed: {},
  mounted() {},
  methods: {
    // 打开视频详情的抽屉，传id
    toDetail(id, avatar, code, nickname) {
      if (!avatar && !code && !nickname) {
        this.$message({
          message: "暂无主播视频详情！",
          type: "warning",
        });
        return;
      }
      this.$emit("handleClickVideo", id);
      this.$store.commit("new_video/CHANGE_LOADING", true);
    },
    openVideo(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.video-item {
  // width: 200px;
  // height: 300px;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  // margin: 0 60px 20px 0;

  :hover .mask-button {
    opacity: 1 !important;
  }

  &-top {
    // height: 224px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    position: relative;
    background: #000;

    .mask {
      background: rgba(3, 3, 3, 0.41);
      // background: red;
      width: 100%;
      height: 122px;
      // padding: 120px 10px 0;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 50;
      .title {
        font-size: 12px;
        color: #fff;
        height: 38px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .time {
        font-size: 12px;
        color: #fff;
        height: 25px;
        line-height: 25px;
      }
      .action {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          width: 52px;
        }

        span {
          color: #fff;
          font-size: 12px;
          margin-top: 4px;
        }
      }
    }

    .mask-button {
      opacity: 0;
      background: rgba(0, 0, 0, 0.6);
      width: 100%;
      height: 100%;
      padding: 60px 0;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 60;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
    }
  }

  &-bottom {
    height: 50px;
    padding-left: 5px;
    background: #fff;
    border-radius: 0 0 8px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      border: 1px solid #ddd;
      border-radius: 15px;
    }
    div {
      width: calc(100% - 40px);
      height: 100%;
      padding: 5px 0 5px 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      .name {
        font-size: 12px;
        height: 14px;
        font-weight: bold;
        color: #333;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        cursor: pointer;
        word-break: break-all;
      }
      .number {
        font-size: 12px;
        color: #999;
        margin-top: 2px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        cursor: pointer;
        word-break: break-all;
      }
    }
  }
}
</style>
